<template>
  <div class="layout-wrapper">
    <Header :routerData="routerData" @dialogVisibleFun=dialogVisibleFun>
    </Header>
    <div id="subapp-viewport"></div>
    <el-dialog title="温馨提示" custom-class="kindly-reminder-dialog" :visible.sync="dialogVisible" top="150px"
      :close-on-click-modal="false" width="400px">
      <span>{{ hintDesc }}</span>
      <span slot="footer" class="dialog-footer">
        <div class="dialog-btn dialog-confirm ZHIHUISHU_QZMD" @click="dialogConfirm">确定</div>
        <div class="dialog-btn dialog-cancel ZHIHUISHU_QZMD" @click="dialogVisible = false">取消</div>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import NProgress from 'nprogress'
import microApps from './micro-app'
import store from '@/store'
import Header from './compoents/public/header.vue'
import {
  getCookie,
  getQuery
} from './assets/const/Utils';
import api from "./fetch/url";
export default {
  name: 'App',
  components: {
    Header,
  },
  data() {
    return {

      dialogVisible: false,
      isLoading: true,
      microApps,
      current: '/AIresource',
      hintDesc: "当前题目未标记，是否确认离开",
      routerData: {
        showlogo: true,
        handle: 0,
        fixedBack: false,
        type: "course",
        name: "高等数学",
        backFont: false, //返回的文案是否展示
        backIcon: false, //带箭头的返回
      },
    }
  },
  watch: {
    isLoading(val) {
      if (val) {
        NProgress.start()
      } else {
        this.$nextTick(() => {
          NProgress.done()
        })
      }
    }
  },
  components: {
    Header
  },
  methods: {
    dialogVisibleFun(routerData) {
      this.routerData = routerData;
      this.hintDesc = this.routerData.hintDesc ? this.routerData.hintDesc : "当前题目未标记，是否确认离开";
      this.dialogVisible = true;
    },
    dialogConfirm() {
      this.dialogVisible = false;
      if (!this.routerData.fixedBack) {
        history.pushState(null, null, this.routerData.parentPath);
      } else {
        window.history.go(-1);
      }
    },
    IEVersion() {
      if (navigator.appName == "Microsoft Internet Explorer" && parseInt(navigator.appVersion.split(";")[1].replace(
        /[ ]/g, "").replace("MSIE", "")) <= 9) {
        return true;
      } else {
        return false;
      }
    },
    goto(item) {
      history.pushState(null, item.activeRule, item.activeRule)
    },
    bindCurrent() {
      const path = window.location.pathname;
      // this.pathHeader(path);
      if (this.microApps.findIndex(item => item.activeRule === path) >= 0) {
        this.current = path
      }
    },
    listenRouterChange() {
      const _wr = function (type) {
        const orig = history[type]
        return function () {
          const rv = orig.apply(this, arguments)
          const e = new Event(type)
          e.arguments = arguments
          window.dispatchEvent(e)
          return rv
        }
      }
      history.pushState = _wr('pushState')

      window.addEventListener('pushState', this.bindCurrent)
      window.addEventListener('popstate', this.bindCurrent)

      this.$once('hook:beforeDestroy', () => {
        window.removeEventListener('pushState', this.bindCurrent)
        window.removeEventListener('popstate', this.bindCurrent)
      })
    },
    getUrlParams(name) { // 不传name返回所有值，否则返回对应值
      let url = window.location.search;
      if (url.indexOf('?') == 1) { return ''; }
      url = url.substr(1);
      url = url.split('&');
      name = name || '';
      let nameres;
      // 获取全部参数及其值
      for (let i = 0; i < url.length; i++) {
        let info = url[i].split('=');
        let obj = {};
        obj[info[0]] = decodeURI(info[1]);
        url[i] = obj;
      }
      // 如果传入一个参数名称，就匹配其值
      if (name) {
        for (let i = 0; i < url.length; i++) {
          for (const key in url[i]) {
            if (key == name) {
              nameres = url[i][key];
            }
          }
        }
      } else {
        nameres = url;
      }
      // 返回结果
      return nameres;
    },
    setzhsCookie(name, val, domain, days) {
      var exp = new Date(),
        d = days || 3;
      exp.setTime(exp.getTime() + d * 24 * 60 * 60 * 1000); //3天过期
      document.cookie = name + "=" + val + ";expires=" + exp.toGMTString() + ";path=/;domain=" + domain;
    },
    setCookie(name, val, days) {
      var exp = new Date(),
        d = days || 3;
      exp.setTime(exp.getTime() + d * 24 * 60 * 60 * 1000); //3天过期
      document.cookie = name + "=" + val + ";expires=" + exp.toGMTString() + ";path=/";
    }
  },
  created() {
    this.bindCurrent();
    NProgress.start();
    if (this.IEVersion()) {
      window.location.href = "//www.zhihuishu.com/updateBrowser.html";
    }
    let userInfo = getCookie('CASLOGC') ? JSON.parse(getCookie('CASLOGC')) : {};
    const path = window.location.pathname;
    const pathName = path.match(/schoolCenterPage/) || path.match(/AIresource/) || [];

    // 免登陆逻辑
    const token = this.getUrlParams("token");
    const cookieToken = getCookie('jt-cas')

    if (!userInfo.uuid && (pathName[0] != 'schoolCenterPage' && pathName[0] != 'AIresource' && path!=='/') && !token && !cookieToken) {
      // TODO:确定定制化主页，然后与晓飞确定参数
      window.location.href = '//passport.zhihuishu.com/login?source=20&service=' + encodeURIComponent(window.location
        .href);
    }
    if (token) {
      this.setzhsCookie('jt-cas', token, 'zhihuishu.com');
      this.setzhsCookie('token-login', true, 'zhihuishu.com');
      this.setzhsCookie('CASLOGC', encodeURIComponent('{"realName":"智慧树","myuniRole":0,"myinstRole":0,"userId":846071169,"headPic":"https://image.zhihuishu.com/zhs/ablecommons/demo/201804/950dbe2c28574f328b7b56121b021006_s3.jpg","uuid":"XAnnGKjw","mycuRole":0,"username":"aizhihuishu"}'), 'zhihuishu.com');
      // this.setzhsCookie('CASLOGC','{"realName":"展佳","myuniRole":1,"myinstRole":0,"userId":802289137,"headPic":"https://image.zhihuishu.com/jsq/android/202104/6183541C1F4D9928AC5E043D18426BFA_s3.jpg","uuid":"Vv489z6p","mycuRole":0,"username":"394624146b284f74aeea1b1057214bf7"}','zhihuishu.com');
    } else {
      this.setzhsCookie('token-login', false, 'zhihuishu.com');
    }
    if (window.location.href.indexOf("https") == -1 && window.location.href.indexOf("http") != -1) {
      if (window.location.href.indexOf("8080") != -1) {
        return;
      }
      let http = window.location.href.split("http")[1];
      window.location.href = "https" + http;
    }
    store.onGlobalStateChange((newState, prev) => {
      let _this = this;
      console.log(newState, "父组件的监听");
    })
  },
  mounted() {
    this.listenRouterChange();
  }
}
</script>

<style lang="scss">
@font-face {
  font-family: "DINAlternate-Bold, DINAlternate";
  src: url("./assets/fonts/DIN\ Alternate\ Bold.ttf");
  /* 数字字体*/
}

html,
body {
  margin: 0 !important;
  padding: 0;
}

.layout-wrapper {
  height: 100%;
}

.layout-wrapper .header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}

#subapp-viewport {
  position: relative;
  display: inherit;
  height: calc(100% - 48px);
  top: 48px;
}

#subapp-viewport>div {
  height: 100%;
}

.kindly-reminder-dialog {
  border-radius: 10px;
  color: #2a2a2a;

  .el-dialog__title {
    font-weight: 600;
  }

  .dialog-btn {
    display: inline-block;
    line-height: 1;
    font-size: 16px;
    padding: 7px 34px;
    border-radius: 4px;
    cursor: pointer;
  }

  .dialog-cancel {
    background: #00bfbf;
    color: #fff;
  }

  .dialog-confirm {
    border: 1px solid #ededed;
    margin-right: 20px;
  }
}
</style>