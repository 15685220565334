<template>
  <div>
    <div class="mobiles-header" v-if="isMobiles"
      :class="[routerData.leave==2 ? 'header-css' : '', {'headerShow': headerShow}]">
      <div class="logo-mobiles ZHIHUISHU_QZMD" v-show="routerData.leave!=3 && routerData.leave!=2" @click="goHome">
        <img :src="imgSrc" alt="智慧树">
      </div>
      <div @click="goBack" class='header-gobacks header-gobackIcons ZHIHUISHU_QZMD' v-show="routerData.leave==2">
        <i class="el-icon-arrow-left"></i>
      </div>
    </div>
    <div v-else class="header" :class="[{'dark':dark},{'headerShow': headerShow}]">
      <div class="logo ZHIHUISHU_QZMD" v-show="routerData.leave!=3" @click="goHome">
        <img :src="imgSrc" alt="智慧树">
      </div>
      <div @click="goBack" class='header-goback ZHIHUISHU_QZMD' v-show="routerData.leave==2">
        <span class="line">｜</span>
        <span class="name">{{routerData.name}}</span>
      </div>

      <div @click="goBack" class='header-goback header-gobackIcon ZHIHUISHU_QZMD' v-show="routerData.leave==3">
        <i class="el-icon-arrow-left"></i>
        <span>返回{{routerData.name}}</span>
      </div>

      <el-dropdown trigger="click" style="float: right;">
        <span class="el-dropdown-link">
          <div slot="reference" class="header-username" @click="showHead">
            <img class="head-img" :src="userInfo.headPic" alt="">
            <span class="username">{{ userInfo.realName }}</span>
            <i class="el-icon-arrow-down" style="color:#DDDDDD;font-weight:600;"></i>

          </div>
        </span>
        <el-dropdown-menu slot="dropdown" style="
    top: 32px !important;
    left: 993px;
    width: 130px !important;
    line-height: 32px;
    text-align: center;
    font-size: 14px;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
      color: #222;cursor: pointer;">
          <div class="dorpdown-panel" v-show="headShow">
            <div class="item ZHIHUISHU_QZMD" @click.stop="logout">安全退出</div>
          </div>
        </el-dropdown-menu>
      </el-dropdown>
      <!-- <div class="dorpdown-panel" v-show="headShow">
            <div class="item ZHIHUISHU_QZMD"
                @click.stop="logout">安全退出</div>
          </div> -->
    </div>
  </div>
</template>
<script>
  import {
    getCookie
  } from '../../assets/const/Utils'
  import store from '@/store'
  export default {
    // props: ["routerData"],
    data() {
      return {
        isShow: true,
        dark: false, // 深色模式
        headerShow: false, // 头部开关
        userInfo: {},
        imgSrc: "https://www.zhihuishu.com/assets/images/home-logo-light.png",
        routerData: {
          leave: 0,
          isPop: 0,
          type: "",
          name: "",
        },
        theme: 'light',
        isMobiles: false,
        headShow: false
      };
    },
    computed: {},
    watch: {
      dark(newValue) {
        console.log('新dark', newValue);
        this.imgSrc = newValue ?
          "https://image.zhihuishu.com/zhs/ablecommons/zhangying/202109/5a748af4116945f198273d422571c295.png" :
          "//www.zhihuishu.com/assets/images/home-logo-light.png"
      }
    },
    created() {
      this.userInfo = getCookie('CASLOGC') ? JSON.parse(getCookie('CASLOGC')) : {};
      let _this = this;
      //父组件监听函数
      store.onGlobalStateChange((newState, prev) => {
        console.log(newState, "父组件的监听");
        _this.routerData = newState.routerData;
        _this.dark = newState.routerData.dark || false;
        _this.headerShow = newState.routerData.headerShow || false;
        this.isShow = newState.routerData.hasOwnProperty('isShow') ? newState.routerData.isShow : true;
      })
      if (_this.isMobile()) {
        _this.isMobiles = true;
      } else {
        _this.isMobiles = false;
      }
    },
    mounted() {},
    methods: {
      //点击logo
      goHome() {
        if (this.routerData.isPop == 1) {
          this.$emit("dialogVisibleFun", this.routerData);
          return;
        }
        history.pushState(null, null, "/AIresource");
      },
      goBack() {
        if (this.routerData.isPop == 1) {
          this.$emit("dialogVisibleFun", this.routerData);
          return;
        }
        if (this.routerData.fixedBack) {
          window.history.go(-1);
          return;
        }
        if (this.routerData.before_leave) {
          return this.routerData.before_leave().then(() => {
            history.pushState(null, null, this.routerData.parentPath);
          }, err => {

          })
        }
        // console.log(this.routerData.parentPath, '跳转路由');
        history.pushState(null, null, this.routerData.parentPath);
      },
      skipHandle() {
        this.$emit('skipHandle');
      },
      showHead() {
        console.log("111");
        this.headShow = true;
      },
      logout() {
        // 清除userType的缓存
        sessionStorage.removeItem('userType');
        // let url = 'https://passport.zhihuishu.com/login?source=20&service=' + encodeURIComponent(
        //   "https://ainew.zhihuishu.com")
        // window.location.href = 'https://passport.zhihuishu.com/logout?service=' + encodeURIComponent(url);
        window.location.href = '//passport.zhihuishu.com/logout?source=20&service=' + encodeURIComponent(window.location.href);
      },
      isMobile() {
        let flag = navigator.userAgent.match(
          /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
        )
        return flag;
      }
    }
  };
</script>

<style lang="scss" scoped>
  .mobiles-header {
    width: 100%;
    padding: 0 60px;
    box-sizing: border-box;
    height: 48px;
    background: #ffffff;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);

    .logo-mobiles {
      cursor: pointer;
      display: block;

      img {
        height: 30px;
        margin-top: 7px;
      }
    }

    .header-gobacks {
      position: relative;
      left: -45px;
      cursor: pointer;

      i {
        float: left;
        line-height: 44px;
        margin-right: 5px;
        font-size: 20px;
      }

      span {
        font-size: 14px;
        float: left;
        line-height: 44px;
        margin-left: 10px;
      }

      .line {
        opacity: .5;
      }

      .name {
        opacity: .8;
      }
    }

    .header-gobackIcons {
      span {
        margin-left: 0px;
      }
    }
  }

  .header-css {
    width: calc(100% + 120px) !important;
    background: #000 !important;
    z-index: 2000000;
    position: absolute;
    top: 0;
    left: 0;
    color: #fff;
  }

  .header {
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    width: 100%;
    padding: 0 60px;
    box-sizing: border-box;
    height: 48px;
    background: #ffffff;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);

    .logo {
      float: left;
      cursor: pointer;

      img {
        height: 30px;
        margin-top: 7px;
      }
    }

    .header-content {
      width: 50%;
      text-align: center;
    }

    .header-goback {
      float: left;
      cursor: pointer;

      i {
        float: left;
        line-height: 44px;
        margin-right: 5px;
      }

      span {
        color: #2a2a2a;
        font-size: 14px;
        float: left;
        line-height: 44px;
        margin-left: 10px;
      }

      .line {
        opacity: .5;
      }

      .name {
        opacity: .8;
      }
    }

    .header-gobackIcon {
      span {
        margin-left: 0px;
      }
    }

    .header-username {
      float: right;
      // width: 25%;
      text-align: right;
      cursor: pointer;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      position: relative;
      padding: 10px 0;

      .head-img {
        width: 32px;
        height: 32px;
        border-radius: 16px;
      }

      .username {
        max-width: 100px;
        margin: 0 10px;
        font-size: 14px;
        font-weight: bolder;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      // &:hover {
      //   .dorpdown-panel {
      //     display: block;
      //   }
      // }

    }

    .dorpdown-panel {
      position: absolute;
      z-index: 999;
      right: -10px;
      top: 46px;
      width: 140px;
      background-color: #fff;
      padding: 10px 0;
      text-align: center;
      font-size: 14px;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
      color: #222;

      .item {
        height: 36px;
        line-height: 36px;

        &:hover {
          background-color: rgba(0, 0, 0, 0.1);
        }
      }

      // height: /;
    }
  }

  .dark {
    color: #FFF;
    background: #232735;
    box-shadow: 0px 1px 10px 0px rgba(94, 101, 107, 0.1);

    .header-goback {
      span {
        color: #FFF;
      }
    }
  }

  .headerShow {
    display: none;
    // height: 0;
  }
</style>