
import {getFetch} from './api'
import {
  getCookie
} from '../assets/const/Utils'
const root ="//hike-ai-course.zhihuishu.com";

export const fixeUuidParam = () => {
  let uuid = getCookie('CASLOGC')?JSON.parse(getCookie('CASLOGC')).uuid: '';
  console.log(uuid,"CASLOGC");
  let dateFormate = Date.parse(new Date());
  return {
    uuid: uuid, // 不要删除，部分接口需要uuid
    date: dateFormate
  };
}
export default {
    statisticsCourseDataByCId(params){
      params = Object.assign(params, fixeUuidParam())
      return getFetch(`${root}/aiCourse/course/statisticsCourseDataByCId`,params)
    }
}


