import store from './store'

const microApps = [
  
  {
    name: 'AIresource',
    entry: process.env.VUE_APP_AI_RES,
    activeRule: '/AIresource'
  },
  {
    name: 'AIcourse',
    entry: process.env.VUE_APP_SUB_AI,
    activeRule: '/AIcourse'
  },  
  {
    name: 'AIteacher',
    entry: process.env.VUE_APP_AI_TEA,
    activeRule: '/AIteacher'
  }
  ,
  {
    name: 'AIstudent',
    entry: process.env.VUE_APP_AI_STU,
    activeRule: '/AIstudent'
  }
  ,
  {
    name: 'AIadmin',
    entry: process.env.VUE_APP_AI_ADM,
    activeRule: '/AIadmin'
  }
  
]

const apps = microApps.map(item => {
  return {
    ...item,
    container: '#subapp-viewport', // 子应用挂载的div
    props: {
      routerBase: item.activeRule, // 下发基础路由
      getGlobalState: store.getGlobalState // 下发getGlobalState方法
    }
  }
})

export default apps
