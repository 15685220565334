import axios from 'axios'
// axios 配置
// axios.defaults.timeout = 30000;
axios.defaults.withCredentials = true;
axios.defaults.headers = {
    'Content-Type' : 'application/json;charset=UTF-8',
}

//POST传参序列化
axios.interceptors.request.use((config) => {
    if (config.method === 'post' || config.method === 'put') {
      config.headers['Content-Type'] = 'application/json'
    }
    config.headers={
        'Content-Type':'application/x-www-form-urlencoded',
    }
    return config;
},(error) =>{
    // _.toast("错误的传参", 'fail');
    return Promise.reject(error);
});

//返回状态判断
axios.interceptors.response.use((res) =>{
    let unAuthCount = 0;
    if(!res.data.status){
      window.location.href = '//passport.zhihuishu.com/login?source=20&service=' + encodeURIComponent(window.location.href);
    }
    if(res.data.status == 10086){
        unAuthCount++
        setTimeout(()=>{
            unAuthCount = 0
            window.location.href = window.location.origin
        }, 1000)
        return Promise.reject(res);
    }else if(res.data.status != 200){
        // _.toast(res.data.msg);
        return Promise.reject(res);
    }
    return res;
}, (error) => {
   // _.toast("网络异常", 'fail');
    return Promise.reject(error);
});


export function postFetch(url, params) {
    // var params = params || {};
    return new Promise((resolve, reject) => {
        axios.post(url, params)
            .then(response => {
                resolve(response.data);
            }, err => {
                reject(err.data);
            })
            .catch((error) => {
               reject(error)
            })
    })
}
export function getFetch(url, params) {
    // var params = params || {};
    return new Promise((resolve, reject) => {
        axios.get(url, {params:params})
            .then(response => {
                resolve(response.data);
            }, err => {
                reject(err.data);
            })
            .catch((error) => {
               reject(error)
            })
    })
}
