/**
 * 获取cookie
 */
export const getCookie=(c_name)=>{
    if (document.cookie.length>0)
     {
     var c_start=document.cookie.indexOf(c_name + "=")
     if (c_start!=-1){ 
        c_start=c_start + c_name.length+1 
        var c_end=document.cookie.indexOf(";",c_start)
       if (c_end==-1) c_end=document.cookie.length
       return decodeURIComponent(document.cookie.substring(c_start,c_end))
       } 
     }
    return ""
}
//获取网址信息
export const getQuery = (param) => {
  var reg = new RegExp("(^|&|\\?)" + param + "=([^&|\\?]*)(&|\\?|$)", "i");
  var r = window.location.href.substr(1).match(reg);
  if (r != null) return decodeURI(r[2]);
  return null;
}
/**
 * 设置cookie
 * @param {*} name 
 * @param {*} val 
 * @param {*} days 
 */
export const setCookie=(name,val,days) =>{
    var exp = new Date(),
        d = days||3;
    exp.setTime(exp.getTime() + d * 24 * 60 * 60 * 1000); //3天过期  
    document.cookie = name + "=" + val + ";expires=" + exp.toGMTString()+";path=/";  
}
/**
 * 删除cookie
 * @param {*} name 
 */
export const delCookie=(name) =>{
    setCookie(name,"",-1);
}
/** 
*  设置图片s1||s2||s3尺寸
*  @param picUrl 图片地址
*  @param picSize 可传s1,s2,s3默认s2
*/
export const getSmallImageUrl=(picUrl,picSize)=>{
    if (picUrl) {
        //如果已经添加s1||s2||s3则不处理
        if (picUrl.indexOf("_s1")!=-1 || picUrl.indexOf("_s2")!=-1 || picUrl.indexOf("_s3")!=-1) {
            return false;
        }
        let x = picUrl.lastIndexOf(".");
        if (x != -1) {
            let prefix = picUrl.substring(0, x);
            let subfix = picUrl.substring(x + 1, picUrl.length);

            if (picSize == "s1") {
                picUrl = prefix + "_s1." + subfix;
            } else if (picSize == "s3") {
                picUrl = prefix + "_s3." + subfix;
            } else {
                picUrl = prefix + "_s2." + subfix;
            }
        }
    } else {
        picUrl = "//image.zhihuishu.com/zhs_yufa_150820/ablecommons/demo/201809/50ccbc9664df4b2db5fd62153a24f110.png"
    }
    return picUrl;
}
export const isEmpty = (val) => {
	var $val = $.trim(val);
	if(val==null)return true;
	if (val == undefined || val == 'undefined')
		return true;
	if (val == "")
		return true;
	if (val.length == 0)
		return true;
	if (!/[^(^\s*)|(\s*$)]/.test(val))
		return true;
	return false;
}
/**
 * 判断ie9及以下浏览器
 */
export const IEVersion = () => {
    if(navigator.appName == "Microsoft Internet Explorer"&&parseInt(navigator.appVersion.split(";")[1].replace(/[ ]/g, "").replace("MSIE",""))<=9){
        return true;
    }else{
        return false;
    }
}
// 时间戳转换为时间   
// 调用方法传参FormatDate(new Date(Number(time)), 'yyyy-MM-dd hh:mm')
export const FormatDate = (date, fmt) => {
    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
    }
    let o = {
        'M+': date.getMonth() + 1,
        'd+': date.getDate(),
        'h+': date.getHours(),
        'm+': date.getMinutes(),
        's+': date.getSeconds()
    };
    for (let k in o) {
        if (new RegExp(`(${k})`).test(fmt)) {
            let str = o[k] + '';
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : padLeftZero(str));
        }
    };
    function padLeftZero (str) {
        return ('00' + str).substr(str.length);
    } 
    return fmt;
}