import Vue from 'vue'
import App from './App.vue'
import { registerMicroApps, start, setDefaultMountApp } from 'qiankun'
import microApps from './micro-app'
import 'nprogress/nprogress.css'
import 'element-ui/lib/theme-chalk/index.css'
import "idempotent-babel-polyfill"
import './assets/fonts/font.css';
import {
  Button,
  Select,
  Table,
  Tooltip,
  Input,
  Option,
  TableColumn,
  Pagination,
  Scrollbar,
  Dialog,
  Drawer,
  Dropdown,
  DropdownItem,
  DropdownMenu
} from "element-ui"

import VueUeditorWrap from "vue-ueditor-wrap"
window.commonComponent = { VueUeditorWrap }
Vue.use(Button);
Vue.use(Select);
Vue.use(Table);
Vue.use(Tooltip);
Vue.use(Input);
Vue.use(Option);
Vue.use(Pagination);
Vue.use(TableColumn);
Vue.use(Scrollbar);
Vue.use(Dialog);
Vue.use(Dropdown);
Vue.use(DropdownItem);
Vue.use(DropdownMenu);



Vue.config.productionTip = false

const instance = new Vue({
  render: h => h(App)
}).$mount('#app')

// 定义loader方法，loading改变时，将变量赋值给App.vue的data中的isLoading
function loader(loading) {
  if (instance && instance.$children) {
    // instance.$children[0] 是App.vue，此时直接改动App.vue的isLoading
    instance.$children[0].isLoading = loading
  }
}

// 给子应用配置加上loader方法
const apps = microApps.map(item => {
  console.log(item,'itemitemitem')
  return {
    ...item,
    loader
  }
})

registerMicroApps(apps, {
  beforeLoad: app => {
    console.log('before load app.name====>>>>>', app.name)
  },
  beforeMount: [
    app => {
      console.log('[LifeCycle] before mount %c%s', 'color: green;', app.name)
    }
  ],
  afterMount: [
    app => {
      console.log('[LifeCycle] after mount %c%s', 'color: green;', app.name)
    }
  ],
  afterUnmount: [
    app => {
      console.log('[LifeCycle] after unmount %c%s', 'color: green;', app.name)
    }
  ]
})
setDefaultMountApp('/AIresource')

const worklist = ["aiquestionbankh5.zhihuishu.com", "ainew.zhihuishu.com", "airesource.zhihuishu.com","aicoursenew.zhihuishu.com","aiteachernew.zhihuishu.com","aistudentnew.zhihuishu.com","aiadminnew.zhihuishu.com"];
start({
  prefetch: true,
  sandbox: false,
  async fetch(url, ...args) {
    if(worklist.some(ele=>url.includes(ele))){
      return window.fetch(url, {
        ...args,
        mode: 'cors',
        credentials: 'include',
      });
    }
    return window.fetch(url, ...args);
  },
  excludeAssetFilter: (url) => {
    const whileList = [];
    const whileword = ["base1.zhihuishu.com", "assets.zhihuishu.com", "lc.zhihuishu.com"];
    if (whileList.includes(url)) {
      return true;
    }
    return whileword.some((w) => {
      return url.includes(w);
    });
  }
})
